$button-diameter: 12px;

$button-not-focused: rgb(220, 220, 220);
$button-close: rgb(255, 97, 89);
$button-minimize: rgb(255, 191, 47);
$button-maximize: rgb(37, 204, 62);

$button-spacing: 4px;

$window-not-focused: rgb(246, 246, 246);
$window-focused: linear-gradient(0deg, rgb(216, 216, 216), rgb(236, 236, 236));
$window-border: rgb(179, 179, 179);

$handle-height: 22px;

$window-width: 640px;
$window-height: 480px;


$green: #00ff00;
$red: rgb(248,108,105);
$white:rgb(204,204,204);
$blue: gb(136,219,253);
$grey:#333333;

body {
	background: #3a7bd5;
	background-image: -webkit-radial-gradient(top, circle cover, #333333 0%, #1f1d1d 80%);
	
	display: flex;
	justify-content: center;
	align-items: center;
}

* {
	box-sizing: border-box;
}

textarea, input, button {
	outline: none;
}

.window-button {
	padding: 0;
	margin: 0;
	margin-right: $button-spacing;

	width: $button-diameter;
	height: $button-diameter;

	background-color: $button-not-focused;

	border: 1px solid rgba(0, 0, 0, 0.2);
	// border-radius: math.div($button-diameter,2);

	color: rgba(0, 0, 0, 0.5);
}

.window {
	animation: bounceIn 1s ease-in-out;
	width: $window-width;
	.handle {
		height: $handle-height;

		background: $window-focused;

		border-top: 1px solid white;
		border-bottom: 1px solid $window-border;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		color: rgba(0, 0, 0, 0.7);

		font-family: Helvetica, sans-serif;
		font-size: 13px;

		line-height: $handle-height;
		text-align: center;
	}

	.buttons {
		position: absolute;
		float: left;

		margin: 0 $button-spacing * 2;

		.close {
			@extend .window-button;
			background-color: $button-close;
		}

		.minimize {
			@extend .window-button;
			background-color: $button-minimize;
		}

		.maximize {
			@extend .window-button;
			background-color: $button-maximize;
		}
	}

	.terminal {
		padding: $button-spacing;

		background-color: black;
		opacity: 0.7;

		display: flex;
		justify-content: center;

		// height: $window-height / 2 - $handle-height;

		color: white;

		font-family: 'Source Code Pro', monospace;
		font-weight: 200;
		font-size: 14px;

		white-space: pre-wrap;
		white-space: -moz-pre-wrap;
		white-space: -pre-wrap;
		white-space: -o-pre-wrap;
		word-wrap: break-word;

		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		
		overflow-y: auto;
		
		// &::after {
		// 	content: "|";
		// 	animation: blink 2s steps(1) infinite;
        // }
        
		padding: 20px;

        
	}
}

.prompt {
	color: rgb(189, 227, 113);
}

.path {
	color: rgb(94, 215, 255);
}

@keyframes blink { 50% { color: transparent; } }
@keyframes bounceIn {
  0% {
    transform: translateY(-1000px);
  }
  60% {
    transform: translateY(200px);
  }
  100% {
    transform: translateY(0px);
  }
}